.DirectPage {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    &__logo {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        & img {
            width: 70px;
            display: inline-block;
        }
    }
    &__loader {
        max-width: 375px;
    }
    &__text {
        & > p {
            text-align: center;
            padding: 0 10%;
        }
    }
}
