.HomePage {
    overflow: hidden;
    padding-top: 10vh;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        & > div {
            width: 70%;
            margin: 0 auto;
        }
    }
}
