.Newsletter {
    input,
    textarea {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    & > div {
        margin-top: 0.5rem;
        @media screen and (min-width: 1024px) {
            margin-top: 1rem;
        }
        & > input {
            width: 100%;
            height: 48px;
            border: 1px solid #dbe1ee;
            padding: 14px;
            outline: none;
            border-radius: 4px;
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }
        & > button {
            color: #0a59c3;
            margin-left: -22px;
        }
    }
}
