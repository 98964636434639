.WelcomePage {
    background-color: #f8faff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    inset: 0;
    z-index: 300;
    overflow: hidden;
    & .time {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 300px;
        height: 300px;
        @media screen and (min-width: 1024px) {
            width: 360px;
            height: 360px;
        }
    }
    & .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        max-width: 5rem;
        margin-top: 2rem;
        @media screen and (min-width: 1024px) {
            & img {
                display: none;
            }
        }
    }
    & .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        min-height: 70vh;

        & .text {
            margin: 0 auto 2rem auto;
        }
        & .link {
            background-color: #0a59c3;
            border-radius: 4px;
            color: white;
            font-weight: bold;
            padding: 0.5rem 1.25rem;
            font-size: 0.75rem;
            @media screen and (min-width: 1280px) {
                font-size: 1rem;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        width: 100%;
        @media screen and (min-width: 1024px) {
            justify-content: space-between;
            padding: 1.5rem 2rem;
        }
        &__logo {
            display: none;
            width: 20%;
            max-width: 5rem;
            @media screen and (min-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__trademark {
            display: flex;
            justify-content: center;
            align-items: center;
            & > p {
                white-space: nowrap;
            }
        }
    }
    & .wave {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        & > img {
            width: 100%;
        }
        @media screen and (min-width: 1024px) {
            bottom: -10vh;
        }
    }
    input,
    textarea {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
}
