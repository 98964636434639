@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';
.Hero {
    display: flex;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        max-height: 90vh;
    }
    @media screen and (min-width: 1280px) {
        padding-left: calc((100% - 1280px) / 2);
        padding-right: calc((100% - 1280px) / 2);
    }

    &__content {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 5%;
        z-index: 10;
        @media screen and (min-width: 550px) and (max-width: 800px) {
            padding: 15%;
        }
        @media screen and (min-width: 801px) and (max-width: 1000px) {
            padding: 5% 25%;
        }
        @media screen and (min-width: 1024px) {
            width: 50%;
            margin: 0;
        }
        &__header {
            font-weight: 600;
            color: #000000;
            font-size: calc(1rem + 1.25vw);
            white-space: nowrap;
            margin-bottom: 1rem;
            @media screen and (min-width: 1024px) {
                font-size: calc(1.5rem + 1vw);
            }
            @include mCdL;
            & p {
                white-space: nowrap;
                & span {
                    color: $primary;
                }
            }
        }
        &__parag {
            display: none;
            font-weight: 300;
            font-size: calc(0.75rem);
            margin-top: 2rem;
            color: #4f596e;
            @include mCdL;
            @media screen and (min-width: 1024px) {
                font-size: calc(1rem + 0.25vw);
                display: unset;
            }
            @media screen and (min-width: 1560px) {
                font-size: calc(1.25rem + 0.45vw);
            }
            &-mobile {
                & > p {
                    text-align: center;
                    margin: 1.5rem auto;
                    font-size: 0.85rem;
                }
                @media screen and (min-width: 1024px) {
                    display: none;
                }
            }
        }
        & .StoreBtn {
            margin-top: 2rem;
        }
        &__qr {
            display: none;
            @media screen and (min-width: 1024px) {
                display: flex;
                margin-top: 2rem;
                width: 150px;
            }
        }
    }
    &__image {
        padding: 10% 0;
        @media screen and (min-width: 801px) and (max-width: 1023px) {
            padding: 15%;
            & img {
                transform: scale(1.1);
            }
        }
        @media screen and (min-width: 1024px) {
            padding: unset;
        }
    }
    &__lottie {
        max-width: 55%;
        @media screen and (min-width: 1024px) {
            max-width: 40vh;
        }
    }
    &__scroll {
        display: none;
        @media screen and (min-width: 1024px) {
            position: absolute;
            right: 5vw;
            bottom: 5vh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        & > .arrowy {
            animation-name: pointDown;
            animation-iteration-count: infinite;
            animation-duration: 1.5s;
            animation-timing-function: ease-in-out;
            animation-direction: alternate-reverse;
        }
    }
}
@keyframes pointDown {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(4px);
    }
}
