@mixin section-h3 {
    font-weight: 600;
    font-size: calc(1rem + 1.25vw);
    text-align: center;
    @media screen and (min-width: 1024px) {
        text-align: left;
        font-size: calc(1.5rem + 1vw);
    }
}
@mixin section-p {
    text-align: center;
    font-size: calc(0.85rem + 0.25vw);
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media screen and (min-width: 1024px) {
        text-align: left;
        font-size: calc(1rem + 0.25vw);
    }
    @media screen and (min-width: 1560px) {
        font-size: calc(1.25rem + 0.5vw);
    }
}

@mixin mCdL {
    text-align: center;
    @media screen and (min-width: 1024px) {
        text-align: left;
    }
}
