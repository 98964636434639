@import '../../sass/mixins.scss';
.Detay {
    & .block {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column-reverse;
        justify-content: flex-end;
        @media screen and (min-width: 1024px) {
            min-height: 90vh;
            min-width: 100vw;
            width: 100vw;
            margin: 0 auto;
            padding: 3% 0;
        }
        @media screen and (min-width: 1280px) {
            padding-left: calc((100% - 1280px) / 2);
            padding-right: calc((100% - 1280px) / 2);
        }

        & > div {
            flex-direction: column-reverse;
            width: 100%;
        }

        &.image-first {
            background-color: #f8faff;
            @media screen and (min-width: 1024px) {
                & > div {
                    flex-direction: row;
                }
            }
        }
        &.content-first {
            @media screen and (min-width: 1024px) {
                & > div {
                    flex-direction: row-reverse;
                }
            }
        }
        & .content {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5%;
            & > h3 {
                @include section-h3;
            }
            & > p {
                @include section-p;
                color: #4f596e;
                @media screen and (min-width: 1280px) {
                    width: 80%;
                }
            }
            @media screen and (min-width: 1024px) {
                flex-basis: 50%;
                flex-shrink: 0;
                min-height: 90vh;
                align-items: flex-start;
            }
        }
        & .image {
            display: flex;
            justify-content: center;
            align-items: center;
            & > .LottieElement {
                max-width: 50%;
                @media screen and (min-width: 1024px) {
                    max-width: 43vh;
                }
            }
            & > img {
                width: 70%;
            }
            @media screen and (min-width: 1024px) {
                min-height: 90vh;
                & > img {
                    transform: scale(0.9);
                }
            }
        }
    }
}
