.AboutPage {
    overflow: clip;
    padding-top: 10vh;
    width: 100%;
    overflow-x: hidden;
    &__frame {
        display: flex;
        width: 100%;
        min-width: 100%;
        min-height: 90vh;
        position: relative;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        padding: 10vh 10vw;
        & > div {
            position: relative;
            z-index: 10;
        }
        &__main {
            display: flex;
            width: 100%;
            flex-direction: column;
            &__title {
                color: #0a59c3;
                font-weight: 600;
                font-size: calc(1.5rem + 0.5vw);
                @media screen and (min-width: 1920px) {
                    font-size: calc(1.5rem + 0.75vw);
                }
            }
            &__parag {
                font-weight: 600;
                font-size: calc(1rem + 0.5vw);
                margin: 1rem 0px;
                @media screen and (min-width: 1920px) {
                    font-size: calc(1.25rem + 0.5vw);
                }
            }
            &__list {
                &__head {
                    font-weight: 600;
                    font-size: calc(0.75rem + 0.35vw);
                    margin: 1rem 0;
                    @media screen and (min-width: 1920px) {
                        font-size: calc(0.85rem + 0.5vw);
                    }
                }
                &__con {
                    @media screen and (min-width: 1024px) {
                        width: 70%;
                    }
                    & li {
                        font-size: calc(0.5rem + 0.5vw);
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 0.5rem;
                    }
                    & .circle {
                        width: 0.25rem;
                        height: 0.25rem;
                        min-width: 0.25rem;
                        min-height: 0.25rem;
                        border-radius: 999px;
                        background-color: #0a59c3;
                        display: flex;
                        transform: translateY(8px);
                        margin-right: 0.5rem;
                    }
                }
            }
        }
        &__brand {
            margin-top: 5vh;
        }
        & > .cloud1 {
            position: absolute;
            top: 5vh;
            left: 0;
            pointer-events: none;
            z-index: 1;
        }
        & > .cloud2 {
            position: absolute;
            right: 0;
            bottom: 0;
            pointer-events: none;
            z-index: 1;
        }
    }
}
