.Footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75vh;
    min-height: 75vh;
    padding: 0px 5%;

    &.white {
        background-color: #fff;
    }
    & > div {
        flex-basis: 33%;
    }
    @media screen and (min-width: 1024px) {
        height: 33vh;
        min-height: 33vh;
        padding: 3%;
        flex-direction: row;
        justify-content: flex-start;
        & > div {
            height: 100%;
        }
        & > div:not(:last-child) {
            flex-basis: 35%;
        }
        & > div:last-child {
            flex-basis: 30%;
        }
    }
    @media screen and (min-height: 1080px) {
        height: 25vh;
        min-height: 25vh;
    }
    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        @media screen and (min-width: 1024px) {
            align-items: flex-start;
            justify-content: space-between;
        }
    }
    &__links {
        display: flex;
        width: 100%;
        &__company,
        &__social {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-basis: 50%;
        }
        & a {
            width: fit-content;
            border-bottom: 1px solid transparent;
            transition: all 0.2s ease;
            &:hover {
                border-bottom: 1px solid #0a59c3;
            }
        }
    }
    &__newsletter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding-top: 3%;
        span {
            color: #929bbf;
            margin: 0.5rem auto;
            @media screen and (min-width: 1024px) {
                margin: 0;
            }
        }
        @media screen and (min-width: 640px) {
            width: 60%;
            margin: 0 auto;
        }
        @media screen and (min-width: 1024px) {
            padding-top: 0;
        }
        & .Newsletter {
            @media screen and (min-width: 1920px) {
                width: 60%;
            }
        }
    }
    &.blue {
        background-color: #0a59c3;
        .Footer__links {
            color: #fff;
        }
        .Footer__newsletter {
            label {
                color: #fff;
            }
        }
    }
}
