.ComingSoon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > div {
        flex-basis: 100%;
    }
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        align-items: stretch;
        height: 90vh;
        min-height: 90vh;
        & > div {
            flex-basis: 50%;
        }
    }
    &__visual {
        display: flex;
        justify-content: flex-end;
        @media screen and (min-width: 1024px) {
            & img {
                max-width: 80%;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
        &__sticker {
            display: flex;
            align-items: center;
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: 80%;
            }
        }
        &__title {
            width: 100%;
            font-weight: 600;
            font-size: calc(1.25rem + 0.5vw);
            margin-top: 5vh;
            @media screen and (min-width: 1024px) {
                width: 80%;
            }
        }
        &__parag {
            color: #4f596e;
            margin-top: 1rem;
            & p {
                font-weight: 300;
                font-size: calc(1rem + 0.25vw);
                @media screen and (min-width: 1560px) {
                    font-size: calc(1.25rem + 0.5vw);
                }
            }
            & p:last-child {
                margin-top: 5vh;
            }
            @media screen and (min-width: 1024px) {
                width: 80%;
            }
        }
    }
}
