@import '../../sass/variables.scss';
.Navigation {
    display: flex;
    width: 100vw;
    min-height: 10vh;
    border-bottom: 1px solid #dbe1ee;
    position: fixed;
    overflow: visible;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: white;
    justify-content: space-between;
    @media screen and (min-width: 1024px) {
        & > .Navigation__logo {
            flex-basis: 20%;
        }
        & > .Navigation__links {
            flex-basis: 50%;
        }
    }
    @media screen and (min-width: 1024px) {
        padding: 0 5%;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 3%;
        @media screen and (min-width: 1280px) {
            padding-left: 0;
        }
        & img {
            width: 70px;
            display: inline-block;
            @media screen and (min-width: 1024px) {
                width: 90px;
            }
        }
    }
    &__links {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        & > ul {
            display: none;
            width: 90%;
            align-self: stretch;
            justify-content: space-evenly;
            @media screen and (min-width: 1024px) {
                display: flex;
            }
            & a {
                font-weight: 600;
                font-size: 1vw;
            }
            & > li {
                align-self: stretch;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 2.5px solid transparent;
                &.active {
                    border-bottom: 2.5px solid $primary;
                    border-radius: 1.5px;
                    transition: all 0.4s ease;
                }
            }
            & .download {
                border-radius: 4px;
                background-color: #0a59c3;
                color: white;
                align-self: center;
                padding: 0.5rem 2.25rem;
                & > a {
                    font-weight: 300;
                }
            }
        }
        &-mobile {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media screen and (min-width: 1024px) {
                display: none;
            }
        }
    }
    &__email {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex;
            justify-content: flex-end;
            padding-right: 3%;
        }

        @media screen and (min-width: 1280px) {
            padding-right: 0;
        }
        &__subscribe {
            width: 100%;
            align-items: center;
            display: none;
            justify-content: flex-end;
            @media screen and (min-width: 1024px) {
                display: flex;
            }
        }
        &__menu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            transform-origin: left center;
            overflow: hidden;
            @media screen and (min-width: 1024px) {
                display: none;
            }
            & > div {
                background-color: #000;
                height: 3px;
                border-radius: 2px;
                width: 30px;
                transition: 0.5s ease-in-out;
            }
            & div:nth-child(2) {
                margin: 6px 0;
            }
            &-one.open {
                transform: translateY(5px) rotate(45deg);
            }

            &-two.toRight {
                transform: translateX(100px);
            }
            &-two.toLeft {
                transform: translateX(0px);
            }
            &-three.open {
                transform: translateY(-14px) rotate(-45deg);
            }
        }
    }
    &__language {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex;
            padding-right: 3%;
        }
    }
    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 3%;
        @media screen and (min-width: 1024px) {
            display: none;
        }
    }
    &__side {
        position: absolute;
        top: 10vh;
        z-index: 10;
        height: 90vh;
        min-height: 90vh;
        background-color: #fff;
        width: 100vw;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &.open {
            visibility: visible;
        }
        & .bottom-link {
            color: #929bbf;
            align-self: flex-end;
            position: absolute;
            bottom: 1rem;
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 0.85rem;
            padding: 0 1rem;
        }
        & span {
            font-size: calc(1.25rem + 0.5vw);
            cursor: pointer;
        }
        & a {
            font-size: calc(1.25rem + 0.5vw);
        }
        & .about {
            margin: 2rem 0;
        }
    }
}
