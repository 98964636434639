.LanguageSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__main {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 3.5ch;
        & > span {
            font-size: 0.75rem;
            font-weight: 500;
            @media screen and (min-width: 1024px) {
                font-size: 1rem;
            }
        }
        & .arrow {
            transition: all 0.3s ease;
            &.open {
                transform: rotate(180deg);
            }
        }
    }
    &__list {
        position: absolute;
        bottom: 100%;
        right: 0.25rem;
        display: flex;
        flex-direction: column;
        background-color: #f8faff;
        border-radius: 10px;
        padding: 0.75rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        @media screen and (min-width: 1024px) {
            top: 100%;
            right: unset;
            bottom: unset;
        }
        & > span {
            margin: 4px;
            font-size: 0.85rem;
            cursor: pointer;
            color: #929bbf;
            &:hover {
                color: #0a59c3;
            }
        }
    }
}
