@import '../../sass/variables.scss';
.DownloadPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    margin-top: 10vh;

    &__frame {
        display: flex;
        flex: 1;
        width: 100%;
        &__content,
        &__visual {
            flex-basis: 50%;
        }
        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 5%;
            &__title {
                width: 100%;
                font-size: calc(1.5rem + 1vw);
                font-weight: 600;
                font-family: 'Nunito Sans', sans-serif;
                margin-bottom: 10vh;
                & > h2:last-child {
                    color: $primary;
                }
            }
            &__links {
                display: flex;
                width: 100%;
                &__qr {
                    margin-right: 2rem;
                    & img {
                        width: 150px;
                    }
                }
                &__store {
                    font-weight: 300;
                    font-size: calc(0.5rem + 0.75vw);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding-bottom: 0.5rem;
                    &__btns {
                        display: flex;
                        & > span:first-child {
                            margin-right: 1rem;
                        }
                        & > span {
                            cursor: pointer;
                            &:hover {
                                filter: drop-shadow(0.5rem 0.5rem 1rem rgba(189, 179, 180, 0.932));
                                transform: translateY(3px);
                            }
                        }
                    }
                }
            }
        }
        &__visual {
            & img {
                width: 80%;
                height: auto;
                margin: 0 auto;
            }
        }
    }
    & > .ellipeleft {
        position: absolute;
        top: 15vh;
        left: 0;
        pointer-events: none;
        z-index: 1;
    }
    & > .ellipseright {
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 1;
    }
}
